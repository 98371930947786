import { useState } from "react";
import axios from "axios";
import { Toaster } from "../utils/toaster";
import FormatUrl from "../utils/UrlFormatter";

const Login = ({ handleLoggedin }) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleLogin = (ev) => {
    ev.preventDefault();

    if (!username) {
      Toaster(3, "username required");
      return;
    }
    if (!password) {
      Toaster(3, "password required");
      return;
    }

    let url = FormatUrl(`/user/adminLogin`);
    axios
      .post(url, {
        password: window.btoa(password),
        email: username,
      })
      .then(function (response, error) {
        try {
          sessionStorage.setItem("token", response.token.idToken.jwtToken);
          Toaster(1, "Login success");
          handleLoggedin(true);
        } catch (err) {
          const data = response.data;
          sessionStorage.setItem("token", data.token.idToken.jwtToken);
          handleLoggedin(true);
        }
      })
      .catch(function (err) {
        Toaster(2, err.response?.data?.err?.message || "Invalid credentials");
      });
  };

  return (
    <section className="sign-in-page">
      <div className="container p-0">
        <div className="row no-gutters">
          <div className="col-sm-12 align-self-center">
            <div className="sign-in-from bg-white">
              <h1 className="mb-0">Sign in</h1>
              <p>
                Enter your email address and password to access admin panel.
              </p>
              <form className="mt-4" onSubmit={handleLogin} id="login-form">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="text"
                    className="form-control mb-0"
                    id="email"
                    placeholder="Enter email"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    className="form-control mb-0"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-inline-block w-100">
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    id="submit"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
