import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import UserContainer from "../containers/users";
import { authHeader } from "../utils/Constants";

const Home = ({ setPath }) => {
  const [summary, setSummary] = useState({
    user: {
      active_users: 0,
      inactive_users: 0,
    },
    customer: {
      active_customer: 0,
      inactive_customer: 0,
    },
    subscriptions: {
      gold_subscriptions: 0,
      silver_subscriptions: 0,
      bronze_subscriptions: 0,
    },
  });

  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const getCustomers = () => {
    let url = FormatUrl(`/customer/customer?status=true`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setCustomers(data);
      })
      .catch(function (error) {});
  };

  const getUsers = () => {
    let url = FormatUrl(`/user/users`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setUsers(data);
      })
      .catch(function (error) {});
  };

  const getSummary = () => {
    let url = FormatUrl(`/summary/summary`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setSummary(data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    setPath(window.location.pathname);
    getSummary();
    getUsers();
    getCustomers();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
            <div className="iq-card-body">
              <div className="text-center mb-2">
                <div className="rounded-circle iq-card-icon iq-bg-primary">
                  <i className="ri-user-line" />
                </div>
              </div>
              <div className="clearfix" />
              <div className="text-center">
                <h2 className="mb-0">
                  <span className="counter text-success">
                    {summary.user.active_users}
                  </span>
                  {/* <span>K</span> */}
                </h2>
                <h6 className="mb-2">Active users</h6>
                <p className="mb-0 text-secondary line-height">
                  <span className="text-danger mr-2">
                    {summary.user.inactive_users}
                  </span>
                  Inactive users
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
            <div className="iq-card-body">
              <div className="text-center mb-2">
                <div className="rounded-circle iq-card-icon iq-bg-primary">
                  <i className="ri-user-line" />
                </div>
              </div>
              <div className="clearfix" />
              <div className="text-center">
                <h2 className="mb-0">
                  <span className="counter text-success">
                    {summary.customer.active_customer}
                  </span>
                  {/* <span>K</span> */}
                </h2>
                <h6 className="mb-2">Active customers</h6>
                <p className="mb-0 text-secondary line-height">
                  <span className="text-danger mr-2">
                    {summary.customer.inactive_customer}
                  </span>
                  Inactive customers
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
            <div className="iq-card-body">
              <div className="text-center mb-2">
                <div className="rounded-circle iq-card-icon iq-bg-success">
                  <i className="ri-drizzle-line" />
                </div>
              </div>
              <div className="clearfix" />
              <div className="text-center">
                <h2 className="mb-0">
                  <span className="counter text-success">
                    {summary.subscriptions.gold_subscriptions +
                      summary.subscriptions.silver_subscriptions +
                      summary.subscriptions.bronze_subscriptions}
                  </span>
                  {/* <span>K</span> */}
                </h2>
                <h6 className="mb-2">Subscriptions</h6>
                <p className="mb-0 text-secondary line-height">
                  <span className="text-secondary">
                    {summary.subscriptions.gold_subscriptions} GOLD /
                    {summary.subscriptions.silver_subscriptions} SILVER /
                    {summary.subscriptions.bronze_subscriptions} BRONZE
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
            <div
              className="iq-card-body p-0"
              style={{
                background:
                  "url(static/images/page-img/01.png) no-repeat scroll center center",
                backgroundSize: "contain",
                minHeight: "202px",
              }}
            ></div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Active Users </h4>
              </div>
            </div>

            <div className="table-responsive ">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Tenant</th>
                    <th scope="col">Team</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td>{user.username}</td>
                      <td>{user.customerRef ? user.customerRef.name : ""}</td>
                      <td>{user.tenantRef ? user.tenantRef.name : ""}</td>
                      <td>{user.teamRef ? user.teamRef.name : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Active Customers</h4>
              </div>
            </div>

            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Billing</th>
                  <th scope="col">Created at</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.name}</td>
                    <td>{customer.plan}</td>
                    <td>{customer.billing}</td>
                    <td>
                      {customer.metadata && customer.metadata.createdAt
                        ? customer.metadata.createdAt.split("T")[0]
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Pending user requests</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <ul className="tasks-lists m-0 p-0">
                {users.map((user, index) => (
                  <li
                    className="d-flex justify-content-between mb-3 align-items-center"
                    key={index}
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="task-1"
                      />
                      <label className="custom-control-label" htmlFor="task-1">
                        {user.email}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Schedules</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <ul className="schedule-lists m-0 p-0">
                <li className="d-flex justify-content-between mb-4 align-items-center">
                  <div className="media-support-info">
                    <h6 className="mb-2">Skype call with Ruby</h6>
                    <p className="mb-0">
                      <span className="badge badge-primary">11:45 am</span> USA
                    </p>
                  </div>
                  <div className="team-group">
                    <div className="iq-media-group">
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/05.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/06.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between mb-4 align-items-center">
                  <div className="media-support-info">
                    <h6 className="mb-2">Meeting with Team</h6>
                    <p className="mb-0">
                      <span className="badge badge-info">10:30 am</span> Caneda
                    </p>
                  </div>
                  <div className="team-group">
                    <div className="iq-media-group">
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/05.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/06.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/07.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/08.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between mb-4 align-items-center">
                  <div className="media-support-info">
                    <h6 className="mb-2">Data Analysing with Client</h6>
                    <p className="mb-0">
                      <span className="badge badge-warning text-white">
                        02:00 pm
                      </span>{" "}
                      Africa
                    </p>
                  </div>
                  <div className="team-group">
                    <div className="iq-media-group">
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/05.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/06.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between mb-4 align-items-center">
                  <div className="media-support-info">
                    <h6 className="mb-2">conference with Team</h6>
                    <p className="mb-0">
                      <span className="badge badge-success">03:30 am</span>{" "}
                      Australia
                    </p>
                  </div>
                  <div className="team-group">
                    <div className="iq-media-group">
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/05.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/06.jpg"
                          alt=""
                        />
                      </a>
                      <a href="#" className="iq-media">
                        <img
                          className="img-fluid avatar-40 rounded-circle"
                          src="static/images/user/07.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="iq-card iq-card-block iq-card-stretch iq-card-height-half"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <div className="iq-card-body bg-primary rounded">
              <div className="d-flex align-items-center">
                <div className="col-md-8">
                  <p className="text-white">Amount Due</p>
                  <h2 className="text-white"> $5450.90 </h2>
                  <h6 className="text-white"> Milestone Completed </h6>
                  <div className="text-white"> Payment for next week </div>
                </div>
                <div className="col-md-4 chart-icon text-center">
                  <i className="ri-bar-chart-grouped-line font-size-40 text-white" />
                </div>
              </div>
            </div>
          </div>
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height-half">
            <div className="iq-card-body">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="revenue-desc">
                  <h6>Member Profit</h6>
                  <p className="font-weight-light mb-0">
                    {" "}
                    Average Weekly Profit{" "}
                  </p>
                </div>
                <div className="revenue-amount">
                  <p className="text-primary mb-0"> +168.900 </p>
                </div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="revenue-desc">
                  <h6>Total Profit</h6>
                  <p className="font-weight-light mb-0">
                    {" "}
                    Weekly Customer Orders{" "}
                  </p>
                </div>
                <div className="revenue-amount">
                  <p className="text-primary mb-0"> +168.900 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
