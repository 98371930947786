import { useEffect, useState } from "react";
import formatDate from "../utils/configs";

const UserContainer = ({
  users,
  updateRole,
  updateStatus,
  deleteUser,
  editUser,
  search,
  status,
  role,
}) => {

  const [sortedUsers, setSotedUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ field: '', order: 'desc' });


  const sortUsers = (field) => {
    const isAsc = sortConfig.field === field && sortConfig.order === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';

    const sortedUsers = [...users].sort((a, b) => {
      let fieldA = field === 'createdAt' ? new Date(a.metadata.createdAt) : field === "lastLogout" ? new Date(a.lastLogout) : new Date(a.lastLogin);
      let fieldB = field === 'createdAt' ? new Date(b.metadata.createdAt) : field === "lastLogout" ? new Date(a.lastLogout) : new Date(b.lastLogin);

      return (fieldA - fieldB) * (newSortOrder === 'asc' ? 1 : -1);
    });

    setSotedUsers(sortedUsers);
    setSortConfig({ field, order: newSortOrder });
  };


  useEffect(() => {
    if(!users.length) return;
    setSotedUsers(users);
  },[users])



  return (
    <>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col" style={{ minWidth:'150px' }}>Username</th>
              <th scope="col" style={{ minWidth:'150px' }} className="name-field">Full Name</th>
              <th scope="col" style={{ minWidth:'150px' }} className="name-field">Email</th>
              <th scope="col">Customer</th>
              <th scope="col" className="no-wrap">Signup Method</th>

              <th scope="col">Role</th>
              <th scope="col">Status</th>
              <th scope="col" style={{ width:"400px" }} className="date-field no-wrap pointer" onClick={() => sortUsers('createdAt', 'asc')}>
                <img style={{ width:'14px' }} src="/static/images/sort.svg" /><span>Created At</span>
              </th>



              <th scope="col" className="no-wrap">
                Last Session Time
              </th>
              <th scope="col" className="date-field no-wrap pointer" onClick={() => sortUsers('lastLogin', 'desc')}>
                <img style={{ width:'14px' }} src="/static/images/sort.svg" /> <span>Last Login</span>
              </th>
              <th scope="col" className="no-wrap date-field pointer" onClick={() => sortUsers('lastLogout', 'desc')}>
              <img style={{ width:'14px' }} src="/static/images/sort.svg" /> <span>Last Logout</span></th>
        
     
              <th scope="col" className="no-wrap date-field">Last Status Update</th>
              <th scope="col" className="no-wrap date-field">Last Role Update</th>
          

              


              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          {sortedUsers.filter(user => user?.username?.toLowerCase().includes(search?.toLowerCase()) 
          &&(role ? user.role === role : true) &&(status ? user.status === status : true)).map((user, index) => (
              <tr key={index}>
                <td title={user.username}>{user.username}</td>
                <td>{user.first_name} {user.last_name}</td>
                <td title={user.email}>{user.email}</td>
                <td title={user.customerRef ? user.customerRef.name : ""}>{user.customerRef ? user.customerRef.name : ""}</td>
                <td>{user.googleOrOkta === "google" ? "Google" : "Email"}</td>


                
                <td>
                  <div class="form-group">
                    <select
                      class="form-control form-control-sm small-dropdown"
                      id="exampleFormControlSelect1"
                      value={user.role}
                      onChange={(e) => updateRole(user._id, e.target.value)}
                    >
                      <option value={"REGULAR"}>REGULAR</option>
                      <option value={"ADMIN"}>ADMIN</option>
                      <option value={"SUPER-ADMIN"}>SUPER-ADMIN</option>
                      <option value={"OPERATOR"}>OPERATOR</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <select
                      class="form-control form-control-sm small-dropdown"
                      id="exampleFormControlSelect1"
                      value={user.status}
                      onChange={(e) =>
                        updateStatus(user._id, e.target.value, user.email)
                      }
                    >
                      <option value={"ACTIVE"}>ACTIVE</option>
                      <option value={"IN-ACTIVE"}>IN-ACTIVE</option>
                      <option value={"DISABLED"}>DISABLED</option>
                    </select>
                  </div>

                </td>
                <td className="no-wrap">{formatDate(user?.metadata?.createdAt)}</td>
         
                
                
                <td>{user.lastSessionTime}</td>
                <td className="no-wrap">{user.lastLogin ? formatDate(user.lastLogin) : ""}</td>
                <td className="no-wrap">{user.lastLogout ? formatDate(user.lastLogout) : ""}</td>

                <td className="no-wrap">{user.LastStatusUpdate ? formatDate(user.LastStatusUpdate) : ""}</td>
                <td className="no-wrap">{user.lastRoleUpdate ? formatDate(user.lastRoleUpdate) : ""}</td>

        


                <td className="text-danger">
                  <span
                    className="pointer"
                    onClick={() => deleteUser(user._id, user.username)}
                  >
                    <i class="ri-add-circle-line mr-1"></i>
                    Delete
                  </span>
                  <span className="pointer" onClick={() => editUser(user)}>
                    <i class="ri-edit-box-line pointer ml-4 mr-1"></i>
                    Edit
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default UserContainer;
