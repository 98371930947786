import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import Card from "../component/card.component";
import Modal from "../component/modal.component";
import { Toaster } from "../utils/toaster";
import { authHeader } from "../utils/Constants";

const Customers = ({ setPath }) => {
  const [teams, setTeams] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [team, setTeam] = useState(false);

  // form inputs
  const [name, setName] = useState(null);

  const editTeam = (team) => {
    setOpenModal(true);
    setName(team.name);

    setTeam(team);
  };

  const getTeams = () => {
    let url = FormatUrl(`/team/teams`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setTeams(data);
      })
      .catch(function (error) {});
  };

  const createTeam = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/team/teams`);

    axios
      .post(url, {
        name: name,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setOpenModal(false);
        setTeam(null);
        getTeams();
        Toaster(1, "Team created");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to create team ");
      });
  };

  const updateTeam = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/team/teams/${team._id}`);

    axios
      .patch(url, {
        name: name,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setName(null);
        setOpenModal(false);
        setTeam(null);
        getTeams();
        Toaster(1, "Team updated");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to update team ");
      });
  };

  const deleteTeam_ = (id) => {
    let url = FormatUrl(`/team/teams/${id}`);
    axios
      .delete(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getTeams();
        Toaster(1, "Team deleted");
      })
      .catch(function (error) {
        Toaster(3, "Failed to deleted team");
      });
  };

  const deleteTeam = (id) => {
    let text = "Are you sure you wants to delete this team ?";
    if (window.confirm(text) == true) {
      deleteTeam_(id);
    } else {
      return;
    }
  };

  useEffect(() => {
    getTeams();
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card
            title={"Teams"}
            title2={"Create team"}
            setOpenModal={setOpenModal}
          >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, index) => (
                  <tr key={index}>
                    <td>{team.name}</td>
                    <td>
                      {team.metadata && team.metadata.createdAt
                        ? team.metadata.createdAt.split("T")[0]
                        : ""}
                    </td>

                    <td className="text-danger">
                      <span
                        className="pointer"
                        onClick={() => deleteTeam(team._id)}
                      >
                        <i class="ri-add-circle-line mr-1"></i>
                        Delete
                      </span>
                      <span className="pointer" onClick={() => editTeam(team)}>
                        <i class="ri-edit-box-line pointer ml-4 mr-1"></i>
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>

      <Modal
        title={team ? "Update team" : "Create team"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className="modal-body">
          <form onSubmit={team ? updateTeam : createTeam} id="team-submit">
            <div className="form-group">
              <label htmlFor="exampleInputDisabled1">Team name</label>
              <input
                type="text"
                className="form-control"
                id="team-input"
                onChange={(e) => setName(e.target.value)}
                placeholder="Team name"
                value={name}
              />
            </div>

            <button type="submit" className="btn btn-primary" id="team-submit">
              {team ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Customers;
