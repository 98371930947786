import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import Card from "../component/card.component";
import Modal from "../component/modal.component";
import { Toaster } from "../utils/toaster";
import { authHeader } from "../utils/Constants";

const Subscriptions = ({ setPath }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState(null);
  const [planType, setPlanType] = useState("BRONZE");

  const [subscription, setSubscription] = useState(null);

  const editSubscription = (subscription) => {
    setOpenModal(true);
    setName(subscription.name);
    setPlanType(subscription.planType);

    setSubscription(subscription);
  };

  const getSubscriptions = () => {
    let url = FormatUrl(`/subscription/subscriptions`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setSubscriptions(data);
      })
      .catch(function (error) {});
  };

  const createSubscription = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/subscription/subscription`);

    if (!name) {
      Toaster(3, "Name required");
      return;
    }
    if (!planType) {
      Toaster(3, "planType required");
      return;
    }

    axios
      .post(url, {
        name: name,
        planType: planType,
      })
      .then(function (response, error) {
        let data = response.data;
        setOpenModal(false);
        getSubscriptions();
        Toaster(1, "Subscription created");
      }, authHeader)
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Subscription already exist");
      });
  };

  const updateSubscription = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/subscription/subscription/${subscription._id}`);

    axios
      .patch(url, {
        name: name,
        planType: planType,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setOpenModal(false);
        getSubscriptions();
        setName(null);
        setPlanType(null);
        setSubscription(null);
        Toaster(1, "Subscription updated");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to update subscription");
      });
  };

  const deleteSubscription = (id) => {
    let url = FormatUrl(`/subscription/subscription/${id}`);

    axios
      .delete(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setName(null);
        setPlanType(null);
        getSubscriptions();
        Toaster(1, "Subscription deleted");
      })
      .catch(function (error) {
        Toaster(2, "Failed to  delete subscription");
      });
  };

  useEffect(() => {
    getSubscriptions();
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card
            title={"Subscriptions"}
            title2={"Create subscription"}
            setOpenModal={setOpenModal}
          >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">planType</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription, index) => (
                  <tr key={index}>
                    <td>{subscription.name}</td>
                    <td>{subscription.planType}</td>
                    <td>{subscription.created.split("T")[0]}</td>

                    <td className="text-danger">
                      <span
                        className="pointer"
                        onClick={() => deleteSubscription(subscription._id)}
                      >
                        <i class="ri-add-circle-line mr-1"></i>
                        Delete
                      </span>
                      <span
                        className="pointer"
                        onClick={() => editSubscription(subscription)}
                      >
                        <i class="ri-edit-box-line pointer ml-4 mr-1"></i>
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>

      <Modal
        title={subscription ? "Update subscription" : "Create subscription"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className="modal-body">
          <form
            onSubmit={subscription ? updateSubscription : createSubscription}
            id="subscription-form"
          >
            <div className="form-group">
              <label htmlFor="exampleInputDisabled1">Plan Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputDisabled1"
                onChange={(e) => setName(e.target.value)}
                placeholder="Plan name"
                value={name}
              />
            </div>

            <div class="form-group">
              <label htmlFor="exampleInputDisabled1">Plan type</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={(e) => setPlanType(e.target.value)}
                value={planType}
              >
                <option value={"BRONZE"} id="plan-bronze">
                  BRONZE
                </option>
                <option value={"SILVER"} id="plan-silver">
                  SILVER
                </option>
                <option value={"GOLD"} id="plan-gold">
                  GOLD
                </option>
              </select>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              id="subscription_-ubmit"
            >
              {subscription ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Subscriptions;
