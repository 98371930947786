const Card = (props) => (
  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
    <div className="iq-card-header d-flex justify-content-between">
      <div className="iq-header-title">
              <h4 className="card-title">{props.title}</h4>
      </div>



       {
          props.status && (
            <div>
                <select className="form-control form-control-sm" onChange={props.onStatusChange}>
                  <option value="">Filter status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="IN-ACTIVE">In-Active</option>
                </select>
            </div>
          )
        }

        {
          props.role && (
            <div>
                <select className="form-control form-control-sm" onChange={props.onRoleChange}>
                  <option value="">Filter role</option>
                  <option value="REGULAR">Regular</option>
                  <option value="ADMIN">Admin</option>
                  <option value="SUPER-ADMIN">Super-admin</option>
                  <option value="OPERATOR">Operator</option>
                </select>
            </div>
          )
        }
  



        {
          props.search && (
            <div>
                <input type="text" className="form-control form-control-sm" onChange={props.onChange} style={{ width:'400px' }} placeholder="Search here .."/>
            </div>
          )
        }
  
      {props.title2 ? (
        <div class="iq-card-header-toolbar d-flex align-items-center">
          <div class="dropdown show">
            <span
              class="dropdown-toggle"
              id="dropdownMenuButton01"
              data-toggle="dropdown"
              aria-expanded="false"
              role="button"
            >
              <a
                class="dropdown-item pointer"
                onClick={() => props.setOpenModal(true)}
              >
                <i class="ri-add-circle-line mr-2"></i>
                {props.title2}
              </a>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
    <div className="iq-card-body pb-0">
      <div className="table-responsive">{props.children}</div>
    </div>
  </div>
);

export default Card;
