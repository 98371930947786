import { useEffect } from "react";

const Modal = (props) => {
  const handleModal = (show) => {
    let body = document.body;
    if (show) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }
  };

  const handleClose = () => {
    handleModal(false);
    props.setOpenModal(false);
  };

  useEffect(() => {
    const { openModal } = props;
    if (openModal) {
      handleModal(true);
    }
  }, [props.openModal]);

  return (
    <>
      {props.openModal ? (
        <div
          className="modal fade show"
          id="exampleModalLong"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          style={{ display: "block", paddingRight: "8px" }}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {props.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                  id="modal-close-button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {props.children}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Modal;
