import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import Card from "../component/card.component";
import Modal from "../component/modal.component";
import UserContainer from "../containers/users";
import { Toaster } from "../utils/toaster";
import { authHeader } from "../utils/Constants";

const Users = ({ setPath }) => {
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [teams, setTeams] = useState([]);
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");


  // form inputs
  const [username, setUsername] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [customerRef, setCustomerRef] = useState(null);
  const [tenantRef, setTenantRef] = useState(null);
  const [teamRef, setTeamRef] = useState(null);

  const editUser = (user) => {
    setOpenModal(true);
    setUsername(user.username);
    setFirstname(user.first_name);
    setLastname(user.last_name);
    setEmail(user.email);
    setPhone(user.phone);
    setCustomerRef(user.customerRef._id);
    setTenantRef(user.tenantRef._id);
    setTeamRef(user.teamRef._id);

    setUser(user);
  };

  const getTeams = () => {
    let url = FormatUrl(`/team/teams`);
    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setTeams(data);
      })
      .catch(function (error) {});
  };

  const getCustomers = () => {
    let url = FormatUrl(`/customer/customer`);
    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setCustomers(data);
      })
      .catch(function (error) {});
  };

  const getTenants = () => {
    let url = FormatUrl(`/tenant/tenants`);
    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setTenants(data);
      })
      .catch(function (error) {});
  };

  const getUsers = () => {
    let url = FormatUrl(`/user/users`);
    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        data.sort((a, b) => {
            // First compare by lastLogin in descending order
            if (a.lastLogin > b.lastLogin) return -1;
            if (a.lastLogin < b.lastLogin) return 1;
            // If lastLogin is the same, compare by metadata.createdAt in descending order
            if (a.metadata.createdAt > b.metadata.createdAt) return -1;
            if (a.metadata.createdAt < b.metadata.createdAt) return 1;
            return 0; // if both are same
        });


        setUsers(data);
      })
      .catch(function (error) {});
  };

  const onboardUser = (user_id, email) => {
    let url = FormatUrl(`/email/onboarding`);
    axios
      .post(url, {
        user_id: user_id,
        email: email,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const createUser = (ev) => {
    ev.preventDefault();
    let url = FormatUrl(`/user/register`);
    axios
      .post(url, {
        username: username,
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone,
        password: password,
        customerRef: customerRef,
        tenantRef: tenantRef,
        teamRef: teamRef,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        console.log(data);

        setOpenModal(false);
        getUsers();
        Toaster(1, "User created");
      })
      .catch(function (error) {
        Toaster(2, "Failed to create user");
      });
  };

  const updateStatus = (id, status, email) => {
    let url = FormatUrl(`/user/user/${id}`);

    axios
      .patch(url, {
        status: status,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
 
        setUsername(null);
        setFirstname(null);
        setLastname(null);
        setEmail(null);
        setPhone(null);
        setCustomerRef(null);
        setTenantRef(null);
        setTeamRef(null);
        getUsers();
        Toaster(1, " Status activated");
        if (status === "ACTIVE") {
          onboardUser(id, email);
        }
      })

      .catch(function (error) {
        Toaster(2, "Failed to update status");
      });
  };

  const updateUser = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/user/user/${user._id}`);

    axios
      .patch(url, {
        username: username,
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone,
        customerRef: customerRef,
        tenantRef: tenantRef,
        teamRef: teamRef,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        setOpenModal(false);

        setUsername(null);
        setFirstname(null);
        setLastname(null);
        setEmail(null);
        setPhone(null);
        setCustomerRef(null);
        setTenantRef(null);
        setTeamRef(null);
        setUser(null);

        Toaster(1, "User updated");
      })
      .catch(function (error) {
        Toaster(2, "Failed to update user");
      });
  };

  const deleteUser_ = (id) => {
    let url = FormatUrl(`/user/user/${id}`);

    axios
      .delete(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        Toaster(1, "User deleted");
      })
      .catch(function (error) {
        Toaster(2, "Failed to delete user");
      });
  };

  const deleteUser = (id, username) => {
    let text = `Are you sure you wants to delete "${username}" user ?`;
    if (window.confirm(text) == true) {
      deleteUser_(id);
    } else {
      return;
    }
  };

  const updateRole = (id, role) => {
    let url = FormatUrl(`/user/user/${id}`);
    axios
      .patch(url, {
        role: role,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        Toaster(1, "User role updated");
      })
      .catch(function (error) {
        Toaster(1, "Failed to update role");
      });
  };

  useEffect(() => {
    getUsers();
    getTenants();
    getCustomers();
    getTeams();
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card
            title={"Users"}
            title2={"Create user"}
            setOpenModal={setOpenModal}
            search={true}
            onChange={(e) => setSearch(e.target.value)}
            status={true}
            role={true}
            onStatusChange={(e) => setStatus(e.target.value)}
            onRoleChange={(e) => setRole(e.target.value)}
          >
            <UserContainer
              users={users}
              updateRole={updateRole}
              updateStatus={updateStatus}
              deleteUser={deleteUser}
              editUser={editUser}
              search={search}
              status={status}
              role={role}
            />
          </Card>
        </div>
      </div>

      <Modal
        title={user ? "Update user" : "Create user"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className="modal-body">
          <form
            class="form-horizontal"
            onSubmit={user ? updateUser : createUser}
            id="user-form"
          >
            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Username:
              </label>
              <div class="col-sm-10">
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="Username"
                  value={username}
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="email"
              >
                Email:
              </label>
              <div class="col-sm-10">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  value={email}
                />
              </div>
            </div>
            {user ? (
              ""
            ) : (
              <div class="form-group row">
                <label
                  class="control-label col-sm-2 align-self-center mb-0"
                  for="pwd1"
                >
                  Password:
                </label>
                <div class="col-sm-10">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="***********"
                  />
                </div>
              </div>
            )}

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                First name:
              </label>
              <div class="col-sm-10">
                <input
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                  class="form-control"
                  id="firstname"
                  placeholder="First name"
                  value={firstname}
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Last name:
              </label>
              <div class="col-sm-10">
                <input
                  onChange={(e) => setLastname(e.target.value)}
                  type="text"
                  class="form-control"
                  id="lastname"
                  placeholder="Last name"
                  value={lastname}
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Phone:
              </label>
              <div class="col-sm-10">
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  class="form-control"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Customer:
              </label>
              <div class="col-sm-10">
                <select
                  className="form-control"
                  id="customer"
                  onChange={(e) => setCustomerRef(e.target.value)}
                  value={customerRef}
                >
                  <option>Select customer</option>
                  {customers.map((tenant, index) => (
                    <option value={tenant._id} key={index}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Tenant:
              </label>
              <div class="col-sm-10">
                <select
                  className="form-control"
                  onChange={(e) => setTenantRef(e.target.value)}
                  value={tenantRef}
                  id="tenant"
                >
                  <option>Select tenant</option>
                  {tenants.map((tenant, index) => (
                    <option value={tenant._id} key={index}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="control-label col-sm-2 align-self-center mb-0"
                for="pwd1"
              >
                Team:
              </label>
              <div class="col-sm-10">
                <select
                  className="form-control"
                  onChange={(e) => setTeamRef(e.target.value)}
                  value={teamRef}
                  id="team"
                >
                  <option>Select team</option>
                  {teams.map((team, index) => (
                    <option value={team._id} key={index}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-primary" id="user-submit">
                {user ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Users;
