import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import Card from "../component/card.component";
import Modal from "../component/modal.component";
import { Toaster } from "../utils/toaster";
import { authHeader } from "../utils/Constants";
import formatDate from "../utils/configs";

const Tenants = ({ setPath }) => {
  const [customers, setCustomers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tenant, setTenant] = useState(null);

  // form inputs
  const [name, setName] = useState(null);
  const [customerRef, setCustomerRef] = useState(null);

  const editTenant = (tenent) => {
    setOpenModal(true);
    setName(tenent.name);
    setCustomerRef(tenent.customerRef._id);

    setTenant(tenent);
  };

  const getCustomers = () => {
    let url = FormatUrl(`/customer/customer`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setCustomers(data);
      })
      .catch(function (error) {});
  };

  const getTenants = () => {
    let url = FormatUrl(`/tenant/tenants`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setTenants(data);
      })
      .catch(function (error) {});
  };

  const createTenant = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/tenant/tenants`);

    axios
      .post(url, {
        name: name,
        customerRef: customerRef,
      }, authHeader)
      .then(function (response, error) {
        setOpenModal(false);
        getTenants();
        setName(null);
        setCustomerRef(null);
        Toaster(1, "Tenant created");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to create tenant");
      });
  };

  const updateTenant = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/tenant/tenants/${tenant._id}`);

    axios
      .patch(url, {
        name: name,
        customerRef: customerRef,
      }, authHeader)
      .then(function (response, error) {
        setOpenModal(false);
        getTenants();
        setName(null);
        setCustomerRef(null);
        setTenant(null);
        Toaster(1, "Status updated");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to update status");
      });
  };

  const deleteTenant_ = (id) => {
    let url = FormatUrl(`/tenant/tenants/${id}`);
    axios
      .delete(url, authHeader)
      .then(function (response, error) {
        getTenants();
        Toaster(1, "Tenant deleted");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to delete tenant");
      });
  };

  const deleteTenant = (id) => {
    let text = "Are you sure you wants to delete this tenant ?";
    if (window.confirm(text) == true) {
      deleteTenant_(id);
    } else {
      return;
    }
  };

  useEffect(() => {
    getCustomers();
    getTenants();
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card
            title={"Tenants"}
            title2={"Create tenant"}
            setOpenModal={setOpenModal}
          >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {tenants.map((tenant, index) => (
                  <tr key={index}>
                    <td>{tenant.name}</td>
                    <td>{tenant.customerRef ? tenant.customerRef.name : ""}</td>

                    <td className="no-wrap">{formatDate(tenant.metadata.createdAt)}</td>


                    <td className="text-danger">
                      <span
                        className="pointer"
                        onClick={() => deleteTenant(tenant._id)}
                      >
                        <i class="ri-add-circle-line mr-1"></i>
                        Delete
                      </span>
                      <span
                        className="pointer"
                        onClick={() => editTenant(tenant)}
                      >
                        <i class="ri-edit-box-line pointer ml-4 mr-1"></i>
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>

      <Modal
        title={tenant ? "Update tenant" : "Create tenant"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className="modal-body">
          <form
            onSubmit={tenant ? updateTenant : createTenant}
            id="tenant-form"
          >
            <div className="form-group">
              <label htmlFor="exampleInputDisabled1">Tenant name</label>
              <input
                type="text"
                className="form-control"
                id="tenant-name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Tenant name"
                value={name}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPlaceholder">Select customer</label>

              <select
                className="form-control"
                onChange={(e) => setCustomerRef(e.target.value)}
                value={customerRef}
                id="customer"
              >
                <option>Select customer</option>
                {customers
                  .filter((customer) => customer.status === true)
                  .map((customer, index) => (
                    <option value={customer._id} key={index}>
                      {customer.name}
                    </option>
                  ))}
              </select>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              id="tenant-submit"
            >
              {tenant ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Tenants;
