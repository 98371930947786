// const LOCAL_IP = "http://localhost:5600";
const DEV_IP = "https://everest.dev.cnox.io";

export const URL = process.env.REACT_APP_EVEREST_URL || DEV_IP;


const jwtToken = sessionStorage.getItem("token"); // Retrieve the token from local storage

export const authHeader = {
    headers: {
      Authorization: `${jwtToken}` // Include the token in the Authorization header
    }
}

