import { useEffect, useState } from "react";
import axios from "axios";
import FormatUrl from "../utils/UrlFormatter";
import Card from "../component/card.component";
import Modal from "../component/modal.component";
import { Toaster } from "../utils/toaster.js";
import { authHeader } from "../utils/Constants.js";
import formatDate from "../utils/configs.js";

const Customers = ({ setPath }) => {
  const [customers, setCustomers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // form inputs
  const [name, setName] = useState(null);
  const [plan, setPlan] = useState("TRIAL");
  const [billing, setBilling] = useState("TRIAL");
  const [logo, setLogo] = useState(null);

  const editCustomer = (customer) => {
    setOpenModal(true);

    setName(customer.name);
    setPlan(customer.plan);
    setBilling(customer.billing);
    setLogo(customer.logo);

    setCustomer(customer);
  };

  const getCustomers = () => {
    let url = FormatUrl(`/customer/customer`);

    axios
      .get(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setCustomers(data);
      })
      .catch(function (error) {});
  };

  const createCustomer = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/customer/customer`);

    axios
      .post(url, {
        name: name,
        plan: plan,
        billing: billing,
        logo: logo,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        setOpenModal(false);
        getCustomers();
        setName(null);
        setBilling(null);
        setPlan(null);
        setLogo(null);
        Toaster(1, "Customer created");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to create customer");
      });
  };

  const updateCustomer = (ev) => {
    ev.preventDefault();

    let url = FormatUrl(`/customer/customer/${customer._id}`);

    axios
      .patch(url, {
        name: name,
        plan: plan,
        billing: billing,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getCustomers();
        setOpenModal(false);

        setName(null);
        setBilling(null);
        setPlan(null);
        setCustomer(null);
        setLogo(null);
        Toaster(1, "Customer updated");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to update customer");
      });
  };

  const updateCustomerStatus = (id, status) => {
    let url = FormatUrl(`/customer/customer/${id}`);

    axios
      .patch(url, {
        status: status,
      }, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getCustomers();
        Toaster(1, "Status updated");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to update status");
      });
  };

  const deleteCustomer_ = (id) => {
    let url = FormatUrl(`/customer/customer/${id}`);

    axios
      .delete(url, authHeader)
      .then(function (response, error) {
        let data = response.data;
        getCustomers();
        Toaster(1, "Customer deleted");
      })
      .catch(function (error) {
        console.log(error);
        Toaster(2, "Failed to delete customer");
      });
  };

  const deleteCustomer = (id, username) => {
    let text = `Are you sure you wants to delete "${username}" customer ?`;

    if (window.confirm(text) == true) {
      deleteCustomer_(id);
    } else {
      return;
    }
  };

  useEffect(() => {
    getCustomers();
    setPath(window.location.pathname);
  }, []);

  const uploadImage = (file) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    let url = FormatUrl("/fileUpload/file-upload");

    axios
      .post(url, formData, authHeader)
      .then(function (response, error) {
        let location = response.data.response_data.Location;
        setLogo(location);
        setLoading(false);
        Toaster(1, "Logo uploaded");
      })
      .catch(function (error) {
        setLoading(false);
        Toaster(2, "Failed to upload logo");
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card
            title={"Customers"}
            title2={"Create customer"}
            setOpenModal={setOpenModal}
            search={true}
            onChange={(e) => setSearch(e.target.value)}          
            >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col" className="no-wrap">Customer Type</th>
                  <th scope="col">User Count</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Billing</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="no-wrap">Created at</th>
                  <th scope="col" className="no-wrap">Last Status Update</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {customers.filter(customer => customer.name.toLowerCase().includes(search.toLowerCase())).map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.name}</td>
                    <td>{customer.securityType === "Gen-AI Security" ? "AI" : "Data"}</td>
                    <td>{customer.userCount}</td>
                    <td>{customer.plan}</td>
                    <td>{customer.billing}</td>
                    <td>
                      <div class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline">
                        <div class="custom-switch-inner">
                          <input
                            type="checkbox"
                            class="custom-control-input bg-success"
                            id={`customSwitch-${index}`}
                            checked={customer.status}
                            onChange={(e) =>
                              updateCustomerStatus(
                                customer._id,
                                e.target.checked
                              )
                            }
                          />
                          <label
                            class="custom-control-label"
                            for={`customSwitch-${index}`}
                          >
                            <span class="switch-icon-left">
                              <i class="fa fa-info"></i>
                            </span>
                            <span class="switch-icon-right">
                              <i class="fa fa-info"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </td>
                   
<td className="no-wrap">{formatDate(customer.metadata.createdAt)}</td>
<td className="no-wrap">{formatDate(customer.LastStatusUpdate)}</td>


                    <td className="text-danger">
                      <span
                        className="pointer"
                        onClick={() => deleteCustomer(customer._id, customer.name)}
                      >
                        <i class="ri-add-circle-line mr-1"></i>
                        Delete
                      </span>
                      <span
                        className="pointer"
                        onClick={() => editCustomer(customer)}
                      >
                        <i class="ri-edit-box-line pointer ml-4 mr-1"></i>
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>

      <Modal
        title={customer ? "Update customer" : "Create customer"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className="modal-body">
          <form
            onSubmit={customer ? updateCustomer : createCustomer}
            id="submit-btn"
          >
            <div className="form-group">
              <div class="uploadbox">
                {loading ? (
                  <label for="file-upload" class="custom-file-upload">
                    <img
                      src="/static/img/loader.gif"
                      style={{ width: "100px", marginTop: "20px" }}
                    />
                  </label>
                ) : logo ? (
                  <label for="file-upload" class="custom-file-upload">
                    <img
                      src={logo}
                      style={{ width: "100px", marginTop: "20px" }}
                    />
                  </label>
                ) : (
                  <label for="file-upload" class="custom-file-upload">
                    <i class="las la-cloud-upload-alt"></i>{" "}
                    <span>Upload logo</span>
                  </label>
                )}

                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  accept={"*"}
                  onChange={(e) => uploadImage(e.target.files[0])}
                />
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="form-group">
              <label htmlFor="exampleInputDisabled1">Customer name</label>
              <input
                type="text"
                className="form-control"
                id="customer-name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Customer name"
                value={name}
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputPlaceholder">Plan Name</label>

              <select
                className="form-control"
                onChange={(e) => setPlan(e.target.value)}
                value={plan}
                id="plan-name"
              >
                <option value={"TRIAL"} id="plan-trial">
                  TRIAL
                </option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputPlaceholder">Billing Type</label>
              <select
                className="form-control"
                onChange={(e) => setBilling(e.target.value)}
                value={billing}
                id="billing"
              >
                <option id="billing-trial" value={"TRIAL"}>
                  TRIAL
                </option>
              </select>
            </div>

            <button type="submit" className="btn btn-primary" id="submit">
              {customer ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Customers;
