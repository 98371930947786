import { useEffect, useState } from "react";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// pages
import Users from "./pages/Users";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Customers from "./pages/Customers";
import Tenants from "./pages/Tenants";
import Teams from "./pages/Teams";

// common components
import SideBar from "./component/sidebar.component";
import Topbar from "./component/topbar.component";
import Subscriptions from "./pages/Subscriptions";

const App = () => {
  const [loggendIn, setLoggendIn] = useState(false);
  const [path, setPath] = useState("/");

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token) {
      setLoggendIn(true);
    }
  }, []);

  return (
    <div>
      <Router>
        {loggendIn ? (
          <div>
            <div className="wrapper">
              <SideBar path={path} />
              <Topbar />

              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <Routes>
                    <Route
                      path="/teams"
                      element={<Teams setPath={setPath} />}
                    />
                  </Routes>
                  <Routes>
                    <Route
                      path="/subscriptions"
                      element={<Subscriptions setPath={setPath} />}
                    />
                  </Routes>
                  <Routes>
                    <Route
                      path="/tenants"
                      element={<Tenants setPath={setPath} />}
                    />
                  </Routes>
                  <Routes>
                    <Route
                      path="/customers"
                      element={<Customers setPath={setPath} />}
                    />
                  </Routes>
                  <Routes>
                    <Route
                      path="/users"
                      element={<Users setPath={setPath} />}
                    />
                  </Routes>

                  <Routes>
                    <Route path="/" element={<Home setPath={setPath} />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Routes>
              <Route
                path="/"
                element={<Login handleLoggedin={setLoggendIn} />}
              />
            </Routes>
          </div>
        )}
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default App;
